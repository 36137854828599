import request from '@/common/js/request'

// 将code传给后台
export const send_code = (code,sharesn) => {
    return request({
        method: 'get',
        url: '/api/user_info?code=' + code + '&share_sn=' + sharesn
    })
}

export const getSign = (url,unionid) => {
    return request({
        method: 'GET',
        url: '/api/wechat/sign?url='+ url + '&union_id=' + unionid,
    })
}

