<template>
  <div class="content">
    <!-- <div>测试unionid</div> -->
    <div class="authbtn"  @click="get_Code">
      <img  src="https://static.sprucesmart.com/hbturn/authbtn.png"/>
    </div>
    <!-- <div>{{ data }}</div> -->
  </div>
</template>

<script>
import { send_code, getSign } from "@/common/js/auth.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      unionid: "",
      code: "",
      openId: "",
      data: {},
      share_sn: ""
    };
  },

  created() {
    // this.get_Code();
    var sharesn = this.getParam(window.location.href, 'share_sn')
    this.share_sn = sharesn || ''
    console.log('sharesn', sharesn)

    this.code = this.$options.methods.getUrlParam("code");
    if(this.code !== null || this.code !== "") {
      this.userInfo()
    }
    // getSign(window.location.href).then(res=>{
    //   console.log('signdata', res)
    //   wx.config({
    //     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId: res.data.app_id, // 必填，公众号的唯一标识
    //     timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: res.data.noncestr, // 必填，生成签名的随机串
    //     signature: res.data.signature,// 必填，签名
    //     jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
    //   });
    //   wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
    //     wx.updateAppMessageShareData({
    //       title: '分享', // 分享标题
    //       desc: '分享', // 分享描述
    //       link: 'https://api-dev.sprucesmart.com/fe/wxmp/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //       imgUrl: 'http://static.sprucesmart.com/d6cf5b6a02d211ea9bf400163e03d698.jpg', // 分享图标
    //       success: function () {
    //         // 设置成功
    //       }
    //     })
    //   });
    //   wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
    //     wx.updateTimelineShareData({
    //       title: '分享', // 分享标题
    //       desc: '分享', // 分享描述
    //       link: 'https://api-dev.sprucesmart.com/fe/wxmp/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //       imgUrl: 'http://static.sprucesmart.com/d6cf5b6a02d211ea9bf400163e03d698.jpg', // 分享图标
    //       success: function () {
    //         // 设置成功
    //       }
    //     })
    //   });
    // }).catch(error=>{
    // console.log('error', error)
    // })
  },
  mounted() {},
  methods: {
    // 获取code
    async get_Code() {
      const APPID = "wxef266b2a26655ef1";
      this.code = this.$options.methods.getUrlParam("code");
      
      const local = window.location.href; //本地的地址
      if (this.code == null || this.code === "") {
        // 微信登录
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          APPID +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope=snsapi_userinfo&state=spl&connect_redirect=1#wechat_redirect";
        // window.location.href =
        //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        //   APPID +
        //   "&redirect_uri=" +
        //   encodeURIComponent(local) +
        //   "&response_type=code&scope=snsapi_base&state=spl#wechat_redirect";
        console.log("r", this.code);
        console.log("请授权后参与活动");
        await this.userInfo()
        // this.isShow = false;
        // 传给后台
      } else {
        // 同意授权
        console.log("同意授权");
        await this.userInfo();
      }
      // return this.code;
    },
    getUrlParam(param) {
      var current_index = window.location.href.indexOf(param);
      if (current_index == -1) {
        return null;
      }
      return window.location.href.match(/code=(\S*)&state=/)[1];
    },
    getParam(url, name) {
      try {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = url.split("?")[1].match(reg);
        if (r != null) {
          return r[2];
        }
        return ""; //如果此处只写return;则返回的是undefined
      } catch (e) {
        return ""; //如果此处只写return;则返回的是undefined
      }
    },
    // getshareUrlParam (name){
    //   var after = window.location.href.split("?")[1];
    //   console.log('after', after)
    //   if(after)
    //   {
    //       var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    //       var r = after.match(reg);
    //       if(r != null)
    //       {
    //           return  decodeURIComponent(r[2]);
    //       }
    //       else
    //       {
    //           return null;
    //       }
    //   }
    // },

    // 将code传给后台
    async userInfo() {
      var sharesn = this.getParam(window.location.href, 'share_sn')
      this.share_sn = sharesn || ''
      console.log('sharesn', this.share_sn)
      await send_code(this.code, this.share_sn).then(res => {
        console.log("获取用户信息", res.data);
        if (res.data.errcode == 0) {
          console.log("res.data.body.url", res.data.body.url);
          window.location.href = res.data.body.url;
          this.data = res.data;
          this.openId = res.data.openid;
          localStorage.setItem("openid", res.data.openid);
          // this.getSign(window.location.href)
        }
      });
    }
    // getSignFuc() {
    //   const getSign()
    // }
  }
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(https://assets-cdn.sprucesmart.com/activity/20210903/bg.png);
  background-size: 100% 100%;
}
.authbtn {
  position: absolute;
  bottom: 20px;
  width: 357px;
  height: 78px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
