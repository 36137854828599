import axios from 'axios'

const request = axios.create({
  // baseURL: '/wechat'   
  baseURL: ''   
  // baseURL: 'https://api-dev.sprucesmart.com/wxmp/'   //测试
  // baseURL: 'http://qhzl.sprucesmart.com/api/'

})

request.defaults.responseType = 'json'
request.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
request.interceptors.request.use(function (config) {
  // // 判断用户是否登录
  const token = window.localStorage.getItem('token')
  // // 如果用户已登录就为请求接口统一添加用户 token
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})
request.interceptors.response.use(function (response) {
  return response
}, function (error) {
  return Promise.reject(error)
})

export default request